import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { myConfig } from "../../config";
import axios from "axios";
//componentes
import Paso1 from "./Paso1";
import Paso2 from "./Paso2";
import Paso3 from "./Paso3";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: 80,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ["Ingrese su correo", "Seleccione la hora para anular", "Resumen"];
}

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [correo, setCorreo] = React.useState("");
  const [hora, setHora] = React.useState(0);
  const [datosHoras, setDatosHoras] = React.useState([]);
  const [errorCorreo, setErrorCorreo] = React.useState("");
  const [errorHora, setErrorHora] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");

  useEffect(() => {
    const CargarHoras = async () => {
      try {
        let res = await axios.post(myConfig.urlApi.getHorasPaciente, {
          correo: correo,
        });
        let json = res.data;
        setDatosHoras(json);
      } catch (error) {
        console.error(error);
      }
    };

    CargarHoras();
  }, [correo]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Paso1
            setErrorCorreo={setErrorCorreo}
            errorCorreo={errorCorreo}
            setCorreo={setCorreo}
            correo={correo}
          />
        );
      case 1:
        return (
          <Paso2
            data={datosHoras}
            errorHora={errorHora}
            setErrorHora={setErrorHora}
            setHora={setHora}
            correo={correo}
            hora={hora}
          />
        );
      case 2:
        return (
          <Paso3
            correo={correo}
            hora={hora}
            fecha={datosHoras.filter((h) => h.idHora === hora)}
          />
        );
      default:
        return "";
    }
  }

  const handleNext = () => {
    let verror = false;
    switch (activeStep) {
      case 0:
        if (correo.length <= 0) {
          setErrorCorreo("Debe ingresar su correo");
          verror = true;
        }

        if (verror === true) {
          return;
        }
        break;

      case 1:
        if (hora === 0) {
          setErrorHora(
            errorHora.length > 0 ? errorHora : "Debe seleccionar una hora"
          );
          verror = true;
        }
        if (verror === true) {
          return;
        }
        break;

      default:
        break;
    }

    if (activeStep === steps.length - 1) {
      GuardarDatos();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function APIGuardaDatos() {
    try {
      let res = await axios.get(myConfig.urlApi.setAnulaHora + "/" + hora);
      let json = res.data;

      if (json.error) {
        setMensaje(
          "Ups! Ha ocurrido un error, favor anular hora escribiendo al whatsapp +56 9 6213 62 61."
        );
        console.error(json.mensaje);
      } else {
        setMensaje("Se ha anulado la hora exitósamente");
      }
    } catch (error) {
      setMensaje(
        "Ups! Ha ocurrido un error, favor anular hora escribiendo al whatsapp +56 9 6213 62 61."
      );
      console.error(error);
    }
  }

  const GuardarDatos = () => {
    APIGuardaDatos();
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography component={"span"}>
                {getStepContent(index)}
              </Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Atras
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1
                      ? "Finalizar"
                      : "Siguiente"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography component={"span"}>{mensaje}</Typography>
        </Paper>
      )}
    </div>
  );
}
