import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import GoogleFonts from "../GoogleFonts";

const useStyles = makeStyles({
  root: {
    maxWidth: 200,
  },
  media: {
    height: 150,
  },
});

export default function Precios({ imagen, titulo, valor }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia className={classes.media} image={imagen} title={titulo} />
        <CardContent>
          <GoogleFonts
            textAlign="center"
            fontFamily="Merienda"
            fontSize={"2rem"}
          >
            {valor}
          </GoogleFonts>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
