import React from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function Paso3(props) {
  const classes = useStyles();
  //const [selectedValue, setSelectedValue] = React.useState('a');

  const handleChange = (valor) => {
    //setSelectedValue(valor);
    props.setTerapeuta(valor);
    props.setErrorTerapeuta("");
  };

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <Typography
          component="span"
          variant="caption"
          className={classes.inline}
          color="error"
        >
          {props.errorTerapeuta}
        </Typography>
        <List className={classes.root}>
          {props.data.map((piv, index) => (
            <React.Fragment key={`list-terapeutas-${piv.id}`}>
              <ListItem
                button
                alignItems="flex-start"
                onClick={() => handleChange(piv.id)}
              >
                <ListItemAvatar>
                  <Avatar alt={piv.nombre} src="/images/avatarcaro.png" />
                </ListItemAvatar>
                <ListItemText
                  primary={piv.nombre}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {piv.especialidad}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <Radio
                    checked={props.terapeuta === piv.id}
                    onChange={() => handleChange(piv.id)}
                    value={piv.id}
                    name="radio-button-terapeuta"
                    inputProps={{ "aria-label": "A" }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
