import React from "react";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  padding: {
    paddingBottom: 10,
  },
}));

export default function Paso1(props) {
  const classes = useStyles();
  const handleChange = (event) => {
    props.setModalidad(event.target.value);
  };
  const handleChangePrevision = (event) => {
    props.setPrevision(event.target.value);
  };

  return (
    <Grid container>
      <Grid className={classes.padding} item xs={12} md={3}>
        <FormControl fullWidth>
          <InputLabel id="select-prevision">Previsión</InputLabel>
          <Select
            labelId="simple-select-label-prevision"
            id="simple-select-prevision"
            label="Previsión"
            value={props.prevision}
            onChange={handleChangePrevision}
          >
            {props.datosPrevision.map((piv, index) => (
              <MenuItem
                key={`select-prevision-${index}`}
                disabled={piv.disabled}
                value={piv.value}
              >
                {piv.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
