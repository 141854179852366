import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function GoogleFonts({ children, ...props }) {
  return (
    <Typography align="justify" component="div" style={{ width: "100%" }}>
      <Box {...props}>{children}</Box>
    </Typography>
  );
}
