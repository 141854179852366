import React, { useEffect } from "react";
import { useUsuario } from "../../context/usuario";
import { myConfig } from "../../config";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Cargando from "../Cargando";

import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function HorasTerapeuta() {
  const { usuario, logout } = useUsuario();
  const [data, setData] = React.useState([]);
  const [cargando, setCargando] = React.useState(false);

  useEffect(() => {
    const cargaHorasTerapeuta = async () => {
      try {
        setCargando(true);
        let res = await axios.get(myConfig.urlApi.getHorasTerapeuta, {
          headers: { Authorization: `Bearer ${usuario.token}` },
        });
        let json = res.data.reverse();

        setData(json);
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          logout();
        }
      }
      setCargando(false);
    };
    cargaHorasTerapeuta();
  }, []);

  return (
    <React.Fragment>
      <Cargando open={cargando} />
      <Grid container>
        <Grid item xs={12}>
          <Box pt={3} display="flex" justifyContent="center">
            <Typography variant="button">Mis Horas</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Hora</TableCell>
                <TableCell align="left">Nombre</TableCell>
                <TableCell align="left">Correo</TableCell>
                <TableCell align="left">Teléfono</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((_) => (
                <TableRow key={_.fecha}>
                  <TableCell component="th" scope="row">
                    {_.fecha}
                  </TableCell>
                  <TableCell align="left">{_.paciente}</TableCell>
                  <TableCell align="left">{_.correo}</TableCell>
                  <TableCell align="left">{_.telefono}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
