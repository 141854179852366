import React from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Testomonio from "./Testimonio";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from '@mui/material/LinearProgress';

const testimonios = [
  {
    nombre: "Carla",
    edad: "31",
    testimonio: "Carolina es una excelente profesional, te sientes cómoda, en familia… tomé la terapia pre operación bariátrica, sin embargo trabajamos tanto y más… Carolina es comprometida, muy humana. Siempre se acomodaba a mis tiempos, a pesar de tener agenda completa, te ayuda a buscar hora prontamente. Recomendada mil por ciento. - año 2023"
  },
  {
    nombre: "Maria del Pilar",
    testimonio: "Me encantan las sesiones, Carolina es una excelente profesional,me siento contenida y escuchada, me hacen entender lo que conversamos y el proceso terapéutico para mi bienestar con mis tema bariátrico, autoestima  entre otros. Me es muy cómodo que sean sesiones online, siempre puntuales y con una gran disposición. Me siento más liviana en cada sesión y noto el cambio en el tiempo que llevo en sesiones. - año 2023",
    edad: ""
  },
  {
    nombre: "Yessica",
    edad: "",
    testimonio: "Gracias a Carolina por estar siempre conmigo me ha ayudado en mi proceso bariátrico desde el primer mes de operada y ya llevo 1 año y 5 meses,  y ha sido un proceso muy exitoso gracias a la ayuda de Carolina. - año 2023"
  },
  {
    nombre: "Pia Javiera",
    edad: "",
    testimonio: "Conocí a Carolina hace 1 año. Me ha acompañado en todo mi proceso bariátrico, ha sido la mejor terapeuta que he tenido. me enseñó a reconocer la ansiedad y por primera vez en la vida que me la mostró como amiga y no como enemiga, de la mano por primera vez en la vida me entregó técnicas valiosas para poder controlarla, me ha ayudado a comprender las señales que emanan de mi cuerpo y por ende a comprender las emociones, me ha ayudado a conocerme a mi misma a quererme y a respetarme, me ha acompañado en un proceso de duelo bastante difícil y caótico, por último me ha ayudado a estar en paz y tranquila conmigo misma vinculando de manera sana conmigo y mi entorno. - año 2023",
  },
  {
    nombre: "Johana",
    edad: "",
    testimonio: "Carolina Molina una gran amiga así la considero soy su paciente hace más de un año y con ella ha sido todo más fácil me ha ayudado a reencontrarme como mujer y a superar dolores que tenía muy guardados .Soy paciente bariátrica hace más de un año dónde no ha sido para nada fácil  me ha ayudado en todo lo que conlleva mentalmente está operación  siempre muy profesional en todo. - año 2023",
  },
  {
    nombre: "Constanza O.",
    edad: "26",
    testimonio:
      "Soy paciente de Carolina Molina, una excelente psicóloga quien me ha servido de mucha ayuda en esta nueva etapa de mi vida que voy a comenzar tras una cirugía bariátrica y quien mejor que ella que ya ha pasado por esta experiencia, es una muy linda persona de confianza y una muy buena profesional.",
  },
  {
    nombre: "Gabriela F.",
    edad: "38",
    testimonio:
      "Llegué por casualidad a su consulta , en un momento donde la única opción era online, tuve dudas en un principio pero hoy no me arrepiento, hemos tenido un par de sesiones y es muy dedicada, comprensiva, se toma el tiempo de entender y ponerse en el lugar del otro... La recomiendo un 100% y aprovecho de felicitarla por lo perseverante y poner a sus pacientes en primer lugar... Muchas gracias.",
  },
  {
    nombre: "Lina U.",
    edad: "28",
    testimonio:
      "Llevo un mes aproximádamente con Carolina tratando algunos problemas de mi vida el cual ha sido muy satisfactorio su ayuda como psicóloga, te brinda la seguridad y la confianza de decirle tus problemas para tratar de encontrar una solución, aún sigo con ella y la recomiendo a muchos conocidos porque su experiencia se hace sentir en cada sesión y su profesionalismo.",
  },
  {
    nombre: "Nairelis D.",
    edad: "31",
    testimonio:
      "Carolina es excelente, empática, amorosa. Desde que hago terapia es la primera psicóloga que consigo con la que me siento a gusto en cada sesión, su apoyo ha sido fundamental para sanar y poder sobre llevar cada uno de mis procesos.",
  },
  {
    nombre: "Jacqueline G.",
    edad: "54",
    testimonio:
      "Una psicóloga dedicada con sus pacientes, se da el tiempo de escuchar, se nota que se ocupa de sus pacientes porque se comunica con ellos en momentos de no estar en sesión, o sea igual siento que existo en su vida fuera de la sesión, no soy un número más.",
  },
  {
    nombre: "Maribel V. ",
    edad: "33",
    testimonio:
      "Vocación en un 100%. Preocupada 24-7 más allá de su consulta. Excelente profesional enfocada en avanzar en mis terapias.",
  },
  {
    nombre: "Jocelyn G.",
    edad: "41",
    testimonio: "Excelente atención, 100% recomendada.",
  },
];

export default function CarruselTestimonios() {
  const[valor, setValor] = React.useState(0);
  const[progress, setProgress] = React.useState(0);

  useEffect(()=>{
    const SetValueTestimonio = () => { 
      let counter = 0;
      const i = setInterval(function(){    
      
  
      if(counter >= testimonios.length)
        counter = 0;
    
      setValor(counter);   
      setProgress(0);
      counter++;    
      }, 20000);
    };

    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 5);
    }, 1000);
    
    SetValueTestimonio();
  },[]);

  const GetNombre = (value) =>{
    return testimonios[value].nombre
  }

  const GetTestimonio = (value) =>{
    return testimonios[value].testimonio
  }

  const GetEdad = (value) =>{
    return (testimonios[value].edad != '' ?`(${testimonios[value].edad})` : '')
  }
  
  return (
  <div>
    <Typography align="justify" component="div">
      <Box
        display="flex"
        justifyContent="left"
        fontFamily="Yanone Kaffeesatz"
        fontSize={"h4.fontSize"}
        m={2}
      >
        {GetNombre(valor)} {GetEdad(valor)}
      </Box>
    </Typography>
    <Typography align="justify" component="div">
      <Box
        display="flex"
        justifyContent="center"
        fontFamily="Yanone Kaffeesatz"
        fontSize={"h5.fontSize"}
        m={2}
      >
        {GetTestimonio(valor)}
      </Box>
    </Typography>
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  </div>
  );
}
