import React, {useEffect} from 'react';
import './App.css';
import Layout from './components/Layout';
import {useUsuario} from './context/usuario';

function App() {
    const {signin, getContext, logout} = useUsuario();
    const [esperar, setEsperar] = React.useState(true);
  
    useEffect(async () => {  

      try{
        if (!sessionStorage.getItem('mentesana-token')){
          setEsperar(false);
          return;
        }
  
        let token = sessionStorage.getItem('mentesana-token');
        const jsonContext = await getContext(token)
        jsonContext.token = token;
        signin(jsonContext);
      }
      catch(error){
        logout();
      }
      finally{
        setEsperar(false);
      }
    }, []) 

  return (
    <React.Fragment>
      {esperar?'':<Layout /> }
    </React.Fragment>
    
  );
}

export default App;
