import React, { useEffect } from "react";
import { useUsuario } from "../../context/usuario";
import { myConfig } from "../../config";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Cargando from "../Cargando";
import Alerta from "../Alerta";

export default function Datos() {
  const { usuario, logout } = useUsuario();
  const [ciudad, setCiudad] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [pais, setPais] = React.useState("");
  const [rut, setRut] = React.useState("");
  const [telefono1, setTelefono1] = React.useState("");
  const [especialidad, setEspecialidad] = React.useState("");
  const [pass1, setPass1] = React.useState("");
  const [pass2, setPass2] = React.useState("");
  const [cargando, setCargando] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorPass, setErrorPass] = React.useState(false);

  useEffect(() => {
    const cargaHorasTerapeuta = async () => {
      try {
        setCargando(true);
        let res = await axios.get(myConfig.urlApi.getTerapeuta, {
          headers: { Authorization: `Bearer ${usuario.token}` },
        });
        let json = res.data;

        if (json.codigo === 1) {
          setCiudad(json.ciudad);
          setNombre(json.nombre);
          setCorreo(json.correo);
          setPais(json.pais);
          setRut(json.rut);
          setEspecialidad(json.especialidad);
          setTelefono1(json.telefono1);
        }
      } catch (error) {
        console.error(error);

        if (error.response.status === 401) {
          logout();
        }
      }
      setCargando(false);
    };
    cargaHorasTerapeuta();
  }, []);

  const handleSave = () => {
    let dataSave = {};
    const apiSave = async () => {
      try {
        setCargando(true);
        let res = await axios.post(myConfig.urlApi.setTerapeuta, dataSave, {
          headers: { Authorization: `Bearer ${usuario.token}` },
        });
        let json = res.data;
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          logout();
        }
      }
      setCargando(false);
      setOpen(true);
    };

    setErrorPass(false);
    if (pass1 === pass2) {
      dataSave = {
        ciudad: ciudad,
        nombre: nombre,
        correo: correo,
        pais: pais,
        pass: pass1,
        rut: rut,
        telefono1: telefono1,
        especialidad: especialidad,
        token: usuario.token,
      };
      apiSave();
    } else {
      setErrorPass(true);
    }
  };

  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
  };
  const handleChangeCorreo = (event) => {
    setCorreo(event.target.value);
  };
  const handleChangeCiudad = (event) => {
    setCiudad(event.target.value);
  };
  const handleChangePais = (event) => {
    setPais(event.target.value);
  };
  const handleChangeRut = (event) => {
    setRut(event.target.value);
  };
  const handleChangeTelefono1 = (event) => {
    setTelefono1(event.target.value);
  };
  const handleChangeEspecialidad = (event) => {
    setEspecialidad(event.target.value);
  };
  const handleChangePass1 = (event) => {
    setPass1(event.target.value);
  };
  const handleChangePass2 = (event) => {
    setPass2(event.target.value);
  };

  return (
    <React.Fragment>
      <Cargando open={cargando} />
      <Alerta setOpen={setOpen} open={open} mensaje={"Guardado Exitoso"} />
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box display="flex" justifyContent="center">
            <Typography variant="button">Mis Datos</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <TextField
              fullWidth
              id="txtNombre"
              name="txtNombre"
              label="Nombre"
              variant="outlined"
              size="small"
              value={nombre}
              onChange={handleChangeNombre}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <TextField
              fullWidth
              id="txtCorreo"
              name="txtCorreo"
              label="Correo"
              variant="outlined"
              size="small"
              value={correo}
              onChange={handleChangeCorreo}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <TextField
              fullWidth
              id="txtEspecialidad"
              name="txtEspecialidad"
              label="Especialidad"
              variant="outlined"
              size="small"
              value={especialidad}
              onChange={handleChangeEspecialidad}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <TextField
              fullWidth
              id="txtCiudad"
              name="txtCiudad"
              label="Ciudad"
              variant="outlined"
              size="small"
              value={ciudad}
              onChange={handleChangeCiudad}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <TextField
              fullWidth
              id="txtPais"
              name="txtPais"
              label="Pais"
              variant="outlined"
              size="small"
              value={pais}
              onChange={handleChangePais}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <TextField
              fullWidth
              id="txtRut"
              name="txtRut"
              label="Cédula"
              variant="outlined"
              size="small"
              value={rut}
              onChange={handleChangeRut}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <TextField
              fullWidth
              id="txtTelefono1"
              name="txtTelefono1"
              label="Teléfono"
              variant="outlined"
              size="small"
              value={telefono1}
              onChange={handleChangeTelefono1}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <TextField
              error={errorPass}
              fullWidth
              id="txtPass1"
              helperText="No llenar para mantener clave"
              name="txtPass1"
              label="Password"
              type="password"
              variant="outlined"
              size="small"
              defaultValue={pass1}
              onChange={handleChangePass1}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <TextField
              error={errorPass}
              fullWidth
              id="txtPass2"
              helperText="No llenar para mantener clave"
              name="txtPass2"
              label="Repita Password"
              type="password"
              variant="outlined"
              size="small"
              defaultValue={pass2}
              onChange={handleChangePass2}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box p={2}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Guardar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
