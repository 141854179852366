import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import esLocale from "date-fns/locale/es";
import { myConfig } from "../../config";
import axios from "axios";

export default function MaterialUIPickers(props) {
  const [diasOcupados, setDiasOcupados] = React.useState([]);

  const handleDateChange = (date) => {
    props.setFecha(date);
  };

  const handleMonthChange = async (date) => {
    //PARA MEJORARA PERFORMANCE, READONLY EN FECHA Y CARGAR POR MES MOSTRABLE EN EL CALENDARIO
    try {
      let res = await axios.post(myConfig.urlApi.getHorasOcupadasPaciente, {
        cedula: props.cedula,
        prevision: props.prevision,
      });
      let json = res.data;
      setDiasOcupados(json);
    } catch (error) {
      console.error(error);
    }
  };

  const handleError = (err) => {
    if (err !== null) {
      switch (err) {
        case "invalidDate":
          props.setErrorDia("Fecha Inválida");
          break;
        case "shouldDisableDate":
          props.setErrorDia("No hay horas disponibles para este día");
          break;
        case "minDate":
          props.setErrorDia("No hay horas disponibles para este día");
          break;
        default:
          props.setErrorDia("Fecha Inválida");
          break;
      }
    } else {
      props.setErrorDia("");
    }
  };

  React.useEffect(() => {
    handleMonthChange("init");
  }, []);

  function disableDays(date) {
    let validar = 0;

    for (let i = 0; i < diasOcupados.length; i++) {
      var datestring =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        "T00:00";

      if (datestring === diasOcupados[i]) {
        validar = 1;
        break;
      }
    }

    return !Boolean(validar);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <DatePicker
        shouldDisableDate={disableDays}
        variant="inline"
        format="dd/MM/yyyy"
        label="Fecha"
        value={props.fecha}
        minDate={new Date()}
        onChange={handleDateChange}
        onError={handleError}
        renderInput={(params) => (
          <TextField {...params} helperText={props.errorDia} />
        )}
        onMonthChange={handleMonthChange}
      />
    </LocalizationProvider>
  );
}
