import React, {useState} from 'react';
import { useMemo } from 'react';
import axios from 'axios';
import {myConfig} from '../config';

const UsuarioContext = React.createContext();

//Provider: Proveedor de nuestro contexto, es el objeto usuario
//Axios: llamadas al servidor

export function UsuarioProvider(props){
    const [usuario, setUsuario] = useState(null);

    async function getContext(itoken) {

        let res = await axios.get(myConfig.urlApi.whoami, {headers: {'Authorization': `Bearer ${itoken}`}});
        let json = res.data;
        json.token = itoken;
        return json; 
    }

    function signin(icontexto){
        if (usuario == null){
            sessionStorage.setItem("mentesana-token", icontexto.token);
            setUsuario(icontexto);         
        }
    }
    function logout(){
        setUsuario(null);
        sessionStorage.removeItem("mentesana-token");       
    }

    const value = useMemo(() =>{
        return ({
            usuario,
            signin,        
            setUsuario,
            logout,
            getContext,   
        })
    }, [usuario])

    return <UsuarioContext.Provider value={value} {...props} />
}

export function useUsuario(){
    const context = React.useContext(UsuarioContext);
    if (!context){
        throw new Error('useUsuario debe estar dentro del proveedor UsuarioContext');
    }
    return context;
}