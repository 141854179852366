import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import DialogTerapeuta from "./DialogTerapeuta";

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
  },
  media: {
    height: 350,
  },
});

export default function MediaCard() {
  const classes = useStyles();
  const [abrir, setAbrir] = React.useState(false);

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image="/images/caroavatar.png"
            title="Carolina Molina"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Carolina Molina
            </Typography>
            <Typography
              align="justify"
              variant="body2"
              color="textSecondary"
              component="p"
            >
              Psicóloga Clínica, diplomada en Terapia Infantojuvenil, psicología
              comunitaria, intervención social y políticas públicas,
              especializada en psicología bariátrica.
            </Typography>
          </CardContent>
        </CardActionArea>

        <CardActions>
          <Button size="small" color="primary" onClick={() => setAbrir(true)}>
            Leer más sobre mi...
          </Button>
        </CardActions>

        {/* 
      <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
      */}
      </Card>
      <DialogTerapeuta abrir={abrir} setAbrir={setAbrir} />
    </React.Fragment>
  );
}
