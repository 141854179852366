import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function Paso1(props) {
  const handleChangeCorreo = (event) => {
    props.setCorreo(event.target.value);
    props.setErrorCorreo("");
  };

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <Box p={2}>
          <TextField
            error={props.errorCorreo.length > 0 ? true : false}
            helperText={props.errorCorreo}
            fullWidth
            id="email"
            label="Email"
            variant="outlined"
            size="small"
            onChange={handleChangeCorreo}
            defaultValue={props.correo}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
