import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { myConfig } from "../../config";
import axios from "axios";
//componentes
import Paso1 from "./Paso1";
import Paso2 from "./Paso2";
import Paso3 from "./Paso3";
import Paso4 from "./Paso4";
import Paso5 from "./Paso5";
import Paso6 from "./Paso6";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: 80,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: "5px",
    marginTop: "15px",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    "Seleccione Previsión",
    "Ingrese sus Datos",
    "Seleccione Terapeuta",
    "Ingrese día",
    "Seleccione una hora",
    "Confirmación",
  ];
}

export default function VerticalLinearStepper({ valores }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [modalidad, setModalidad] = React.useState("1");
  const [prevision, setPrevision] = React.useState("par");
  const [correo, setCorreo] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [cedula, setCedula] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [pais, setPais] = React.useState("");
  const [terapeuta, setTerapeuta] = React.useState(0);
  const [fecha, setFecha] = React.useState(Date.now());
  const [hora, setHora] = React.useState(0);
  const [horaLabel, setHoraLabel] = React.useState("");
  const [datosTerapeuta, setDatosTerapeuta] = React.useState([]);
  const [datosHoras, setDatosHoras] = React.useState([]);
  const [errorCorreo, setErrorCorreo] = React.useState("");
  const [errorNombre, setErrorNombre] = React.useState("");
  const [errorCedula, setErrorCedula] = React.useState("");
  const [errorPais, setErrorPais] = React.useState("");
  const [errorTelefono, setErrorTelefono] = React.useState("");
  const [errorTerapeuta, setErrorTerapeuta] = React.useState("");
  const [errorHora, setErrorHora] = React.useState("");
  const [errorDia, setErrorDia] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");

  async function CargarTerapeutas() {
    try {
      let res = await axios.get(myConfig.urlApi.getTerapeutas);
      let json = res.data;

      setDatosTerapeuta(json);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    CargarTerapeutas();
  }, []);

  useEffect(() => {
    const CargarHoras = async () => {
      try {
        let dt = new Date(fecha);
        let strdt =
          dt.getFullYear().toString() +
          "-" +
          (dt.getMonth() + 1).toString() +
          "-" +
          dt.getDate().toString();

        let res = await axios.post(myConfig.urlApi.getHoras, {
          terapeuta: terapeuta,
          fecha: strdt,
          cedula: cedula,
          prevision: prevision,
        });

        let json = res.data;
        setDatosHoras(json);
      } catch (error) {
        console.error(error);
      }
    };

    CargarHoras();
  }, [fecha, terapeuta]);

  const datosModalidad = [
    { label: "Tele-Consulta (online)", value: 1, disabled: false },
    { label: "A Domicilio", value: 2, disabled: true },
    { label: "Presencial", value: 3, disabled: true },
  ];

  const datosPrevision = [
    {
      label: `Particular (${valores.particular} c/sesión)`,
      value: "par",
      disabled: false,
      prevision: "Particular",
    },
    {
      label: `Fonasa (${valores.fonasa} c/sesión)`,
      value: "fon",
      disabled: false,
      prevision: "Fonasa",
    },
    {
      label: `Isapre (${valores.isapre} c/sesión)`,
      value: "isa",
      disabled: false,
      prevision: "Isapre",
    },
  ];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Paso1
            datos={datosModalidad}
            datosPrevision={datosPrevision}
            modalidad={modalidad}
            setModalidad={setModalidad}
            prevision={prevision}
            setPrevision={setPrevision}
          />
        );
      case 1:
        return (
          <Paso2
            setErrorCorreo={setErrorCorreo}
            setErrorNombre={setErrorNombre}
            setErrorCedula={setErrorCedula}
            setErrorPais={setErrorPais}
            setErrorTelefono={setErrorTelefono}
            errorCorreo={errorCorreo}
            errorNombre={errorNombre}
            errorCedula={errorCedula}
            errorPais={errorPais}
            errorTelefono={errorTelefono}
            setCorreo={setCorreo}
            correo={correo}
            setNombre={setNombre}
            nombre={nombre}
            setCedula={setCedula}
            cedula={cedula}
            setPais={setPais}
            pais={pais}
            setTelefono={setTelefono}
            telefono={telefono}
          />
        );
      case 2:
        return (
          <Paso3
            errorTerapeuta={errorTerapeuta}
            setErrorTerapeuta={setErrorTerapeuta}
            data={datosTerapeuta}
            setTerapeuta={setTerapeuta}
            terapeuta={terapeuta}
          />
        );
      case 3:
        return (
          <Paso4
            setFecha={setFecha}
            fecha={fecha}
            prevision={prevision}
            cedula={cedula}
            errorDia={errorDia}
            setErrorDia={setErrorDia}
          />
        );
      case 4:
        return (
          <Paso5
            setErrorHora={setErrorHora}
            errorHora={errorHora}
            data={datosHoras}
            setHora={setHora}
            hora={hora}
            setHoraLabel={setHoraLabel}
          />
        );
      case 5:
        return (
          <Paso6
            horaLabel={horaLabel}
            correo={correo}
            terapeuta={
              datosTerapeuta.filter((ter) => ter.id === terapeuta).length > 0
                ? datosTerapeuta.filter((ter) => ter.id === terapeuta)[0].nombre
                : ""
            }
            fecha={fecha}
            hora={hora}
            prevision={
              datosPrevision.filter((x) => x.value === prevision)[0].prevision
            }
          />
        );
      default:
        return "";
    }
  }

  const checkRut = (rut) => {
    //Obligamos que tenga guion

    if (rut.search("-") < 0) {
      return false;
    }

    if (rut.search(/\./) >= 0) {
      return false;
    }

    // Despejar Puntos
    var valor = rut.replace(".", "");

    // Despejar Guión
    valor = valor.replace("-", "");

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0, -1);
    var dv = valor.slice(-1).toUpperCase();

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if (cuerpo.length < 7) {
      return false;
    }

    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;

    // Para cada dígito del Cuerpo
    for (var i = 1; i <= cuerpo.length; i++) {
      // Obtener su Producto con el Múltiplo Correspondiente
      var index = multiplo * valor.charAt(cuerpo.length - i);

      // Sumar al Contador General
      suma = suma + index;

      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }

    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = dv == "K" ? 10 : dv;
    dv = dv == 0 ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (dvEsperado != dv) return false;
    else return true;
  };

  const handleNext = () => {
    let verror = false;
    switch (activeStep) {
      case 1:
        if (correo.length <= 0) {
          setErrorCorreo("Debe ingresar su correo");
          verror = true;
        }
        if (errorCorreo.length > 0) {
          verror = true;
        }
        if (nombre.length <= 0) {
          setErrorNombre("Debe ingresar su nombre");
          verror = true;
        }
        if (cedula.length <= 0) {
          setErrorCedula("Debe ingresar su rut");
          verror = true;
        }
        if (pais.length <= 0) {
          setErrorPais("Debe ingresar su ciudad");
          verror = true;
        }
        if (telefono.length <= 0) {
          setErrorTelefono("Debe ingresar su teléfono");
          verror = true;
        }

        if (!checkRut(cedula)) {
          setErrorCedula("Rut inválido. Ingrese sin puntos y con guión.");
          verror = true;
        }

        if (verror === true) {
          return;
        }
        break;

      case 2:
        if (terapeuta === 0) {
          setErrorTerapeuta("Debe seleccionar un terapeuta");
          verror = true;
        }
        if (verror === true) {
          return;
        }
        break;

      case 3:
        if (errorDia.length > 0) verror = true;
        if (verror === true) return;
        break;

      case 4:
        if (hora === 0) {
          setErrorTerapeuta("Debe seleccionar una hora");
          verror = true;
        }
        if (verror === true) {
          return;
        }
        break;
      default:
        break;
    }

    if (activeStep === steps.length - 1) {
      GuardarDatos();
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function APIGuardaDatos() {
    try {
      let res = await axios.post(myConfig.urlApi.setTomaHora, {
        correo: correo,
        nombre: nombre,
        cedula: cedula,
        pais: pais,
        telefono: telefono,
        hora: hora,
        prevision: prevision,
      });
      let json = res.data;

      if (json.error) {
        if (json.codigo == 250) {
          setMensaje(json.mensaje);
        } else {
          setMensaje(
            "Ups! Ha ocurrido un error, favor tomar hora escribiendo al whatsapp +56 9 6213 62 61."
          );
        }

        console.error(json.mensaje);
      } else {
        setMensaje("Se ha reservado la hora exitósamente");
      }
    } catch (error) {
      setMensaje(
        "Ups! Ha ocurrido un error, favor tomar hora escribiendo al whatsapp +56 9 6213 62 61."
      );
      console.error(error);
    }
  }

  const GuardarDatos = () => {
    APIGuardaDatos();
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography component={"span"}>
                {getStepContent(index)}
              </Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Atras
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1
                      ? "Finalizar"
                      : "Siguiente"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography component={"span"}>{mensaje}</Typography>
        </Paper>
      )}
    </div>
  );
}
