import React from "react";
import { makeStyles } from "@mui/styles";
import Fab from "@mui/material/Fab";
import Slide from "@mui/material/Slide";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function FloatingActionButtons(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.ocultar ? (
        ""
      ) : (
        <Slide
          direction="down"
          in={true}
          timeout={1000}
          mountOnEnter
          unmountOnExit
        >
          <Fab variant="extended" color="secondary" onClick={props.onClick}>
            <EventAvailableIcon className={classes.extendedIcon} />
            AGENDA AQUI TU HORA
          </Fab>
        </Slide>
      )}
    </div>
  );
}
