import React from "react";
import { makeStyles, withStyles } from "@mui/styles";

//material-ui
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import { green } from "@mui/material/colors";
//componentes
import CarruselTestimonios from "../components/Home/CarruselTestimonios";
import GoogleFonts from "../components/GoogleFonts";
import BotonFlotante from "../components/Home/BotonFlotante";
import DialogoTomarHora from "../components/DialogoTomarHora";
import DialogoAnularHora from "../components/DialogoAnularHora";
import DialogoTerapeuta from "../components/DialogoTerapeuta";
import FAQS from "../components/Home/FAQS";
import Precios from "../components/Home/Precios";
import Terapeuta from "../components/Home/Terapeuta";
//iconos
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Titulo from "../components/Home/Titulo";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: 0,
    top: "auto",
    right: "auto",
    bottom: 100,
    left: 20,
    position: "fixed",
    zIndex: 99999,
  },
  regionPrimary: {
    minHeight: "300px",
    backgroundColor: theme.palette.primary.main,

    alignItems: "center",
    color: "#fff",
  },
  regionBlanca: {
    minHeight: "500px",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
  },
  regionBlanca2: {
    minHeight: "500px",
    backgroundColor: "#fff",
    alignItems: "center",
    color: "#7D3C98",
  },
  regionGris: {
    minHeight: "500px",
    width: "100%",
    backgroundColor: "#C0C0C0",
    //display: 'flex',
    alignItems: "center",
  },
  regionEnfoque1: {
    minHeight: "250px",
    backgroundImage: window.matchMedia("(min-width: 500px)").matches
      ? "url('/images/Terapia1_Grande.png')"
      : "url('/images/Terapia1_Chico.png')",
    //display: 'flex',
    alignItems: "center",
    color: "#fff",
  },
  regionEnfoque2: {
    minHeight: "250px",
    backgroundImage: window.matchMedia("(min-width: 500px)").matches
      ? "url('/images/Terapia2_Grande.png')"
      : "url('/images/Terapia2_Chico.png')",
    //display: 'flex',
    alignItems: "center",
    color: "#fff",
  },
  regionEnfoque3: {
    minHeight: "250px",
    backgroundImage: window.matchMedia("(min-width: 500px)").matches
      ? "url('/images/Terapia3_Grande.png')"
      : "url('/images/Terapia3_Chico.png')",
    //display: 'flex',
    alignItems: "center",
    color: "#fff",
  },
  regionEnfoque4: {
    minHeight: "250px",
    backgroundImage: window.matchMedia("(min-width: 500px)").matches
      ? "url('/images/Terapia4_Grande.png')"
      : "url('/images/Terapia4_Chico.png')",
    //display: 'flex',
    alignItems: "center",
    color: "#fff",
  },
  enfoqueTerapia: {
    minHeight: "200px",
    backgroundColor: "#FFF",
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
  },
  parallax: {
    backgroundImage: "url('/images/portada.jpg')",
    minHeight: "600px",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  parallax2: {
    backgroundImage: "url('/images/carrusel1.jpg')",
    minHeight: "400px",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  parallax3: {
    backgroundImage: "url('/images/fondoTerapeutas.jpg')",
    minHeight: "400px",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#fff",
  },
  fondoTitulo: {
    //backgroundColor: '#cc0000',
    background: "rgba(0,0,0,.4)",
    //fontFamily: 'Xanh Mono !important',
    textAlign: "center",
  },
}));

export default function Home(props) {
  const classes = useStyles();

  const valores = {
    fonasa: "$ 13.240.",
    particular: "$ 40.000.",
    isapre: "$ 40.000.",
  };

  const abrirDialogoHora = () => {
    props.setAbrirHora(true);
  };

  const cerrarDialogoHora = () => {
    props.setAbrirHora(false);
  };

  const cerrarDialogoAnular = () => {
    props.setAbrirAnular(false);
  };

  const cerrarDialogoTerapeuta = () => {
    props.setAbrirTerapeuta(false);
  };

  const WhatsAppButton = withStyles((theme) => ({
    root: {
      color: "#fff",
      backgroundColor: green["A700"],
      "&:hover": {
        backgroundColor: green["A200"],
      },
    },
  }))(Button);

  return (
    <React.Fragment>
      {/*<Carrusel />*/}
      <div className={classes.parallax}>
        <div style={{ width: "100%" }}>
          <Box pt={10}>
            <div className={classes.fondoTitulo}>
              <Typography component="div">
                <Box
                  display="flex"
                  justifyContent="center"
                  fontFamily="Merienda"
                  fontSize="h3.fontSize"
                  m={1}
                >
                  Psicóloga Carolina Molina
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  fontFamily="Quicksand"
                  fontSize="h4.fontSize"
                  m={1}
                >
                  Agenda tu hora online.
                </Box>
              </Typography>
            </div>
          </Box>
          <Box display="flex" justifyContent="center" pt={10}>
            <WhatsAppButton
              size="large"
              variant="contained"
              color="success"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=56962136261"
              startIcon={<WhatsAppIcon />}
            >
              {"Contáctame"}
            </WhatsAppButton>
          </Box>
        </div>
      </div>
      <DialogoTomarHora
        open={props.abrirHora}
        cerrar={cerrarDialogoHora}
        valores={valores}
      />
      <DialogoAnularHora
        open={props.abrirAnular}
        cerrar={cerrarDialogoAnular}
      />
      <DialogoTerapeuta
        open={props.abrirTerapeuta}
        cerrar={cerrarDialogoTerapeuta}
      />
      <Box className={classes.fab}>
        <BotonFlotante
          onClick={abrirDialogoHora}
          ocultar={props.abrirHora || props.abrirAnular || props.abrirTerapeuta}
        />
      </Box>

      {/*QUIENES SOMOS*/}
      <div className={classes.regionBlanca}>
        <Box pl={2} pr={2} pt={3} pb={3}>
          <Grid container justifyContent="space-around">
            <Grid item xs={12}>
              <Box pb={3} display="flex" justifyContent="center">
                <Titulo
                  nombre="¿Cuando es el momento de asistir a terapia?"
                  color="#7D3C98"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography align="justify" component="div">
                <Box
                  display="flex"
                  justifyContent="center"
                  fontFamily="Yanone Kaffeesatz"
                  fontSize={"h5.fontSize"}
                  m={2}
                >
                  Cuando nuestra rutina se ve afectada por una dificultad
                  personal, como la ansiedad o malestar, una situación dolorosa,
                  como una enfermedad, duelo, trauma o separación.
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography align="justify" component="div">
                <Box
                  display="flex"
                  justifyContent="center"
                  fontFamily="Yanone Kaffeesatz"
                  fontSize={"h5.fontSize"}
                  m={2}
                >
                  Tus relaciones se ven afectadas, dificultades relacionales,
                  como problemas de pareja, problemas en la familia, o en el
                  trabajo o bien un deseo de cambio o de trabajo en sí mismo.
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography align="justify" component="div">
                <Box
                  display="flex"
                  justifyContent="center"
                  fontFamily="Yanone Kaffeesatz"
                  fontSize={"h5.fontSize"}
                  m={2}
                >
                  Has sufrido un trauma en el pasado y no puedes dejar de pensar
                  en ello. El dolor de una muerte de un ser querido, la pérdida
                  de trabajo pueden ser razón suficiente para buscar apoyo
                  psicológico.
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div>

      {/*PARALLAX NEW*/}
      <div className={classes.parallax2}>
        <div style={{ width: "100%" }}>
          <Box pt={10}>
            <div className={classes.fondoTitulo}>
              <GoogleFonts
                display="flex"
                fontFamily="Courgette"
                justifyContent="center"
                fontSize="2.5rem"
                m={1}
              >
                La terapia te ayuda en diversos ámbitos de tu vida
              </GoogleFonts>
            </div>
          </Box>
        </div>
      </div>

      {/* TESTIMONIOS */}
      <div style={{ minHeight: "500px" }}>
        <Box pt={3} pb={3}>
          <Grid container justifyContent="space-around">
            <Grid item xs={12}>
              <Box pb={3} display="flex" justifyContent="center">
                <Titulo nombre="Testimonios" color="#7D3C98" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <CarruselTestimonios />
            </Grid>
          </Grid>
        </Box>
      </div>

      {/*TERAPEUTAS*/}
      <div className={classes.parallax3}>
        <Box pb={3}>
          <Grid container justifyContent="space-around">
            <Grid item xs={12}>
              <Box
                pt={3}
                pb={3}
                display="flex"
                justifyContent="center"
                color="white"
              >
                <Titulo nombre="Psicóloga" />
              </Box>
            </Grid>
          </Grid>
          <Box pt={6} display="flex" justifyContent="center">
            <Terapeuta />
          </Box>
        </Box>
      </div>

      {/* PROCESO */}
      <div style={{ minHeight: "500px" }}>
        <Box pt={3} pb={3}>
          <Grid container justifyContent="space-around">
            <Grid item xs={12}>
              <Box pb={3} display="flex" justifyContent="center">
                <Titulo nombre="Proceso terapéutico" color="#7D3C98" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={`1`}
                      src={`/images/uno.png`}
                      style={{ height: "80px", width: "80px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={"1"}
                    primary={
                      <Typography
                        align="justify"
                        component="div"
                        style={{ width: "100%" }}
                      >
                        <Box
                          textAlign="left"
                          fontFamily="Courgette"
                          fontSize={"1.3rem"}
                          lineHeight="120%"
                          m={2}
                        >
                          Tendremos 3 sesiones "diagnósticas y/o de hipótesis"
                          en donde recabaremos la información necesaria, para
                          ambos encontrar soluciones a tu problemática.
                        </Box>
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={`1`}
                      src={`/images/dos.png`}
                      style={{ height: "80px", width: "80px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={"1"}
                    primary={
                      <Typography
                        align="justify"
                        component="div"
                        style={{ width: "100%" }}
                      >
                        <Box
                          textAlign="left"
                          fontFamily="Courgette"
                          fontSize={"1.3rem"}
                          lineHeight="120%"
                          m={2}
                        >
                          En la siguiente sesión fijaremos objetivos a tratar, y
                          sobre lo observado en la etapa anterior, acordaremos
                          en conjunto un número aproximado de sesiones para
                          luego ir evaluando los resultados.
                        </Box>
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={`1`}
                      src={`/images/tres.png`}
                      style={{ height: "80px", width: "80px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={"1"}
                    primary={
                      <Typography
                        align="justify"
                        component="div"
                        style={{ width: "100%" }}
                      >
                        <Box
                          textAlign="left"
                          fontFamily="Courgette"
                          fontSize={"1.3rem"}
                          lineHeight="120%"
                          m={2}
                        >
                          Serás parte activa del tratamiento, aprenderás
                          herramientas que te permitan medir objetivamente el
                          problema y tu avance.
                        </Box>
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={`1`}
                      src={`/images/cuatro.png`}
                      style={{ height: "80px", width: "80px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={"1"}
                    primary={
                      <Typography
                        align="justify"
                        component="div"
                        style={{ width: "100%" }}
                      >
                        <Box
                          textAlign="left"
                          fontFamily="Courgette"
                          fontSize={"1.3rem"}
                          lineHeight="120%"
                          m={2}
                        >
                          Realizarás tareas que permitan que elabores de una
                          mejor manera lo que está sucediendo.
                        </Box>
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={`1`}
                      src={`/images/cinco.png`}
                      style={{ height: "80px", width: "80px" }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={"1"}
                    primary={
                      <Typography
                        align="justify"
                        component="div"
                        style={{ width: "100%" }}
                      >
                        <Box
                          textAlign="left"
                          fontFamily="Courgette"
                          fontSize={"1.3rem"}
                          lineHeight="120%"
                          m={2}
                        >
                          Luego de finalizado el proceso de tratamiento
                          realizaremos sesiones de seguimiento.
                        </Box>
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Divider />

      {/*VALORES*/}
      <div className={classes.regionBlanca2}>
        <Box pt={3} pb={3}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box pb={3} display="flex" justifyContent="center">
                <Titulo nombre="Valores de Consulta" />
              </Box>
            </Grid>
            <Grid item>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ width: 80, height: 80 }}>
                      <img width="80" height={"80"} src="/images/fonasa.jpg" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <GoogleFonts
                        display="flex"
                        fontFamily="Courgette"
                        justifyContent="center"
                        fontSize="2.5rem"
                        m={1}
                      >
                        Fonasa
                      </GoogleFonts>
                    }
                    secondary={
                      <GoogleFonts
                        display="flex"
                        fontFamily="Courgette"
                        justifyContent="center"
                        fontSize="1.8rem"
                        m={1}
                      >
                        {valores.fonasa}
                      </GoogleFonts>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ width: 80, height: 80 }}>
                      <img
                        width="82"
                        height={"82"}
                        src="/images/isapres.jpeg"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <GoogleFonts
                        display="flex"
                        fontFamily="Courgette"
                        justifyContent="center"
                        fontSize="2.5rem"
                        m={1}
                      >
                        Isapres
                      </GoogleFonts>
                    }
                    secondary={
                      <GoogleFonts
                        display="flex"
                        fontFamily="Courgette"
                        justifyContent="center"
                        fontSize="1.8rem"
                        m={1}
                      >
                        {valores.isapre}
                      </GoogleFonts>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <MonetizationOnIcon
                      color="secondary"
                      sx={{ width: 70, height: 70 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <GoogleFonts
                        display="flex"
                        fontFamily="Courgette"
                        justifyContent="center"
                        fontSize="2.5rem"
                        m={1}
                      >
                        Particular
                      </GoogleFonts>
                    }
                    secondary={
                      <GoogleFonts
                        display="flex"
                        fontFamily="Courgette"
                        justifyContent="center"
                        fontSize="1.8rem"
                        m={1}
                      >
                        {valores.particular}
                      </GoogleFonts>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Divider />
      {/* FAQS */}
      <div className={classes.regionBlanca2}>
        <Box pt={3} pb={3}>
          <Grid container justifyContent="space-around">
            <Grid item xs={12}>
              <Titulo nombre="Preguntas Frecuentes" color="#7D3C98" />
            </Grid>
            <Grid item xs={12} md={8}>
              <FAQS valores={valores} />
            </Grid>
          </Grid>
        </Box>
      </div>

      {/*CONVENIOS Y FORMAS DE PAGO*/}
      {/*
      <div>
        <Box pt={3} pb={3}>
          <Grid container justify="space-around">
            <Grid item xs={12}>
              <Box pb={3} display="flex" justifyContent="center">
                <Typography
                  color="primary"
                  variant="h2"
                  align="center"
                  gutterBottom
                >
                  CONVENIOS Y FORMAS DE PAGO
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <CarruselPago />
            </Grid>
          </Grid>
        </Box>
      </div>
      */}
      <Box pt={3} pb={3} bgcolor="text.primary" color="white">
        <Grid
          container
          justify="space-around"
          align="center"
          alignItems="center"
        >
          <Grid item xs={6} md={6}>
            <Box display="flex" justifyContent="center">
              <IconButton
                component={Link}
                aria-label="delete"
                color="inherit"
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=56962136261"
              >
                <WhatsAppIcon fontSize="large" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography color="inherit" variant="h5" align="center">
                +56 9 6213 62 61
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
