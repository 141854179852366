import React, { useEffect } from "react";
import { useUsuario } from "../../context/usuario";
import { myConfig } from "../../config";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Cargando from "../Cargando";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "nombre", headerName: "Nombre", width: 300 },
  { field: "cedula", headerName: "Rut", width: 110 },
  { field: "telefono", headerName: "Teléfono", width: 110 },
  { field: "correo", headerName: "Correo", width: 300 },
  { field: "pais", headerName: "Ciudad", width: 150 },
  { field: "cantidad", headerName: "Sesiones", width: 120 },
];

export default function Pacientes() {
  const { usuario, logout } = useUsuario();
  const [data, setData] = React.useState([]);
  const [cargando, setCargando] = React.useState(false);

  useEffect(() => {
    const cargaPacientes = async () => {
      try {
        setCargando(true);
        let res = await axios.get(myConfig.urlApi.getPacientes, {
          headers: { Authorization: `Bearer ${usuario.token}` },
        });
        let json = res.data;

        json.map((_, index) => {
          _.id = index;
        });

        setData(json);
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          logout();
        }
      }
      setCargando(false);
    };
    cargaPacientes();
  }, []);

  return (
    <React.Fragment>
      <Cargando open={cargando} />
      <Grid container>
        <Grid item xs={12}>
          <Box pt={3} display="flex" justifyContent="center">
            <Typography variant="button">Mis Pacientes</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 680, width: "100%" }}>
            <DataGrid rows={data} columns={columns} pageSize={10} />
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
