import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Titulo(props) {
  return (
    <Typography align="justify" component="div" style={{ width: "100%" }}>
      <Box
        textAlign="center"
        fontFamily="Merienda"
        fontSize={"4rem"}
        lineHeight="120%"
        m={2}
        pb={3}
        color={props.color}
      >
        {props.nombre}
      </Box>
    </Typography>
  );
}
