import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function Paso2(props) {
  const classes = useStyles();

  const handleChange = (valor) => {
    props.setHora(valor);
    props.setErrorHora("");
  };

  useEffect(() => {
    if (props.data.length <= 0) {
      props.setErrorHora("Usted no tiene horas asignadas.");
    }
  }, [props]);

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <Typography
          component="span"
          variant="caption"
          className={classes.inline}
          color="error"
        >
          {props.errorHora}
        </Typography>
        <List className={classes.root}>
          {props.data.map((piv, index) => (
            <React.Fragment key={`list-terapeutas-${piv.idHora}`}>
              <ListItem
                button
                alignItems="flex-start"
                onClick={() => handleChange(piv.idHora)}
              >
                <ListItemText
                  primary={piv.terapeuta}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {piv.fecha + " " + piv.hora}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <Radio
                    checked={props.hora === piv.idHora}
                    onChange={() => handleChange(piv.idHora)}
                    value={piv.idHora}
                    name="radio-button-hora"
                    inputProps={{ "aria-label": "A" }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
