import React from "react";
import { makeStyles } from "@mui/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    overflow: "auto",
  },
  nodisponible: {
    color: "#CC0000",
  },
  disponible: {
    color: "#00CC16",
  },
}));

export default function Paso5(props) {
  const classes = useStyles();
  //const [selectedValue, setSelectedValue] = React.useState('a');

  const handleChange = (valor) => {
    //setSelectedValue(valor);
    props.setHora(valor);
    props.setErrorHora("");

    var result = props.data.filter(function (chain) {
      return chain.id === valor;
    })[0].hora;

    props.setHoraLabel(result);
  };

  const Icono = (tipo) => {
    let component = null;
    switch (tipo) {
      case 0:
        component = (
          <Typography
            className={classes.disponible}
            component="span"
            variant="caption"
          >
            Disponible
          </Typography>
        );
        break;

      case 1:
        component = (
          <Typography
            className={classes.nodisponible}
            component="span"
            variant="caption"
          >
            No Disponible
          </Typography>
        );
        break;

      default:
        component = "";
        break;
    }

    return component;
  };

  return (
    <div className={classes.root}>
      <Typography
        component="span"
        variant="caption"
        className={classes.inline}
        color="error"
      >
        {props.errorHora}
      </Typography>
      <List>
        {props.data.map((piv, index) => (
          <React.Fragment key={"list-hora-" + index}>
            <ListItem
              button
              onClick={() => handleChange(piv.id)}
              disabled={piv.estado === 0 ? false : true}
            >
              <ListItemIcon>
                <Radio
                  checked={props.hora === piv.id}
                  value={piv.id}
                  name="radio-button-hora"
                  inputProps={{ "aria-label": "A" }}
                />
              </ListItemIcon>
              <ListItemText primary={piv.hora} />
              {Icono(piv.estado)}
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}
