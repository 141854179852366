import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

//views
import Home from "../views/Home";
//components
import AppBar from "./AppBar";

const theme = createTheme({
  palette: {
    primary: {
      main: "#C270E5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#43b2cf",
      contrastText: "#fff",
    },
  },
});

export default function Layout() {
  const [abrirAnular, setAbrirAnular] = React.useState(false);
  const [abrirHora, setAbrirHora] = React.useState(false);
  const [abrirTerapeuta, setAbrirTerapeuta] = React.useState(false);
  return (
    <ThemeProvider theme={theme}>
      <AppBar
        setAbrirTerapeuta={setAbrirTerapeuta}
        setAbrirHora={setAbrirHora}
        setAbrirAnular={setAbrirAnular}
      />
      <Home
        abrirTerapeuta={abrirTerapeuta}
        setAbrirTerapeuta={setAbrirTerapeuta}
        abrirHora={abrirHora}
        setAbrirHora={setAbrirHora}
        abrirAnular={abrirAnular}
        setAbrirAnular={setAbrirAnular}
      />
    </ThemeProvider>
  );
}
