import React from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { myConfig } from "../config";
import { useUsuario } from "../context/usuario";
import Cargando from "../components/Cargando";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: 80,
  },
}));

export default function Login() {
  const classes = useStyles();
  const { signin, getContext } = useUsuario();
  const [txtUsuario, setTxtUsuario] = React.useState("");
  const [txtPass, setTxtPass] = React.useState("");
  const [error, setError] = React.useState("");
  const [cargando, setCargando] = React.useState(false);

  const handleChangeUsuario = (event) => {
    setTxtUsuario(event.target.value);
  };

  const handleChangePass = (event) => {
    setTxtPass(event.target.value);
  };

  async function getToken() {
    let res = await axios.post(myConfig.urlApi.autenticar, {
      usuario: txtUsuario,
      pass: txtPass,
    });
    let json = res.data;

    return json.token;
  }

  const handleClick = async () => {
    try {
      setError("");
      setCargando(true);
      if (
        txtUsuario
          ? txtUsuario.length
          : 0 > 0 && txtPass
          ? txtPass.length
          : 0 > 0
      ) {
        const token = await getToken();
        const contexto = await getContext(token);
        signin(contexto);
      } else {
        setError("Debe ingresar usuario y contraseña");
      }
    } catch (error) {
      let mensaje = error.response.data.mensaje;
      let statusText = error.response.statusText;

      setError(`${statusText}: ${mensaje}`);
    } finally {
      setCargando(false);
    }
  };

  return (
    <React.Fragment>
      <Cargando open={cargando} />
      <Container fixed>
        <Grid className={classes.root} container>
          <Grid item xs={12} md={3}>
            <Box p={2}>
              <TextField
                fullWidth
                id="usr"
                name="txtUsuario"
                label="Usuario"
                variant="outlined"
                size="small"
                onChange={handleChangeUsuario}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box p={2}>
              <TextField
                fullWidth
                type="password"
                id="pass"
                name="txtPass"
                label="Contraseña"
                variant="outlined"
                size="small"
                onChange={handleChangePass}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box p={2}>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Entrar
              </Button>
            </Box>
            <Typography component="span" variant="caption" color="error">
              {error}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
