import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useUsuario } from "../../context/usuario";
import { myConfig } from "../../config";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

var arrHorasQ = [
  { hora: "02:00", par: true, fon: false, isa: true, nue: false, ant: true },
  { hora: "04:00", par: false, fon: false, isa: true, nue: false, ant: true },
  { hora: "06:00", par: false, fon: false, isa: true, nue: false, ant: true },
  { hora: "08:00", par: false, fon: false, isa: true, nue: false, ant: true },
];

export default function ListaHoras(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [newchecked, setNewChecked] = React.useState(true);
  const [arrHoras, setArrHoras] = React.useState(arrHorasQ);
  const { usuario, logout } = useUsuario();

  const Guardar = async (itipo, ihora) => {
    try {
      let fecha =
        props.selectedDate.getFullYear().toString() +
        "-" +
        (props.selectedDate.getMonth() + 1).toString() +
        "-" +
        props.selectedDate.getDate().toString();
      let res = await axios.post(
        `${myConfig.urlApi.setHorasDisponibleTerapeuta}`,
        { hora: ihora, tipo: itipo, fecha: fecha },
        { headers: { Authorization: `Bearer ${usuario.token}` } }
      );
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        logout();
      }
    }
  };

  const GuardarCheck = async (ivalue, ihora, itipo) => {
    try {
      let fecha =
        props.selectedDate.getFullYear().toString() +
        "-" +
        (props.selectedDate.getMonth() + 1).toString() +
        "-" +
        props.selectedDate.getDate().toString();
      let res = await axios.post(
        `${myConfig.urlApi.setHorasDisponibleTerapeuta}`,
        {
          hora: ihora,
          tipo: itipo,
          fecha: fecha,
          value: Number(ivalue),
        },
        { headers: { Authorization: `Bearer ${usuario.token}` } }
      );
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        logout();
      }
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      Guardar(1, value);
      newChecked.push(value);
    } else {
      Guardar(2, value);
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheck = (event, hora, tipo) => {
    const nuevo = [...arrHoras];
    let index = nuevo.findIndex((x) => x["hora"] === hora);
    let parametro;

    switch (tipo) {
      case "par":
        parametro = "particular";
        break;
      case "fon":
        parametro = "fonasa";
        break;
      case "isa":
        parametro = "isapre";
        break;
      case "nue":
        parametro = "nuevo";
        break;
      case "ant":
        parametro = "antiguo";
        break;
      default:
        parametro = "";
    }

    GuardarCheck(event.target.checked, hora, tipo);
    nuevo[index][parametro] = Number(event.target.checked);
    setArrHoras(nuevo);
  };

  const isChecked = (piv, tipo) => {
    if (tipo == "par") {
      var validar = arrHoras.find(
        (x) => x.hora === piv.hora && x.particular == 1
      );
    }

    if (tipo == "fon") {
      var validar = arrHoras.find((x) => x.hora === piv.hora && x.fonasa == 1);
    }

    if (tipo == "isa") {
      var validar = arrHoras.find((x) => x.hora === piv.hora && x.isapre == 1);
    }

    if (tipo == "nue") {
      var validar = arrHoras.find((x) => x.hora === piv.hora && x.nuevo == 1);
    }

    if (tipo == "ant") {
      var validar = arrHoras.find((x) => x.hora === piv.hora && x.antiguo == 1);
    }

    return Boolean(validar);
  };

  useEffect(() => {
    let arreglo = [];
    props.dataHoras.map((piv, index) => {
      if (piv.nodisponible === 0) {
        arreglo.push(piv.hora);
      }
    });

    console.log(props.dataHoras);

    setChecked(arreglo);

    setArrHoras(props.dataHoras);
  }, [props.dataHoras]);

  return (
    <React.Fragment>
      <TableContainer>
        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Hora</TableCell>
              <TableCell align="right">Particular</TableCell>
              <TableCell align="right">Fonasa</TableCell>
              <TableCell align="right">Isapre</TableCell>
              <TableCell align="right">Nuevos</TableCell>
              <TableCell align="right">Antiguos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dataHoras.map((piv, index) => (
              <TableRow key={`table-horas-${props.dia}-${index}`}>
                <TableCell component="th" scope="row">
                  {piv.hora}
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={isChecked(piv, "par")}
                    onChange={(event) => handleCheck(event, piv.hora, "par")}
                    {...label}
                    disabled={piv.habilitado === 1 ? false : true}
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={isChecked(piv, "fon")}
                    onChange={(event) => handleCheck(event, piv.hora, "fon")}
                    {...label}
                    disabled={piv.habilitado === 1 ? false : true}
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={isChecked(piv, "isa")}
                    onChange={(event) => handleCheck(event, piv.hora, "isa")}
                    {...label}
                    disabled={piv.habilitado === 1 ? false : true}
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={isChecked(piv, "nue")}
                    onChange={(event) => handleCheck(event, piv.hora, "nue")}
                    {...label}
                    disabled={piv.habilitado === 1 ? false : true}
                  />
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={isChecked(piv, "ant")}
                    onChange={(event) => handleCheck(event, piv.hora, "ant")}
                    {...label}
                    disabled={piv.habilitado === 1 ? false : true}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
