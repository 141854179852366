import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function Paso2(props) {
  const handleChangeNombre = (event) => {
    props.setNombre(event.target.value);
    props.setErrorNombre("");
  };

  const handleChangeCedula = (event) => {
    props.setCedula(event.target.value);
    props.setErrorCedula("");

    //console.log(event.target.value);
  };

  const handleChangePais = (event) => {
    props.setPais(event.target.value);
    props.setErrorPais("");
  };

  const handleChangeTelefono = (event) => {
    props.setTelefono(event.target.value);
    props.setErrorTelefono("");
  };

  const handleChangeCorreo = (event) => {
    let strCorreo = event.target.value;
    strCorreo = strCorreo.trim();

    /*Validamos correo*/
    const regexCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regexCorreo.test(strCorreo)) {
      props.setCorreo(strCorreo);
      props.setErrorCorreo("");
    } else {
      props.setCorreo(strCorreo);
      props.setErrorCorreo("Debe ingresar un correo válido");
    }
  };

  const KeyPressCedula = (event) => {
    //48 - 57
    //107
    //75

    if (event.charCode === 45) {
      if (event.target.value.split("-").length - 1 === 1) {
        event.preventDefault();
      }
      return;
    }

    if (event.charCode === 107) {
      event.target.value = event.target.value + "K";
      props.setCedula(event.target.value);
      props.setErrorCedula("");
      event.preventDefault();
      return;
    }

    if ((event.charCode < 48 || event.charCode > 57) && event.charCode !== 75) {
      event.preventDefault();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <Box p={2}>
          <TextField
            error={props.errorCorreo.length > 0 ? true : false}
            helperText={props.errorCorreo}
            fullWidth
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            size="small"
            onChange={handleChangeCorreo}
            defaultValue={props.correo}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box p={2}>
          <TextField
            error={props.errorNombre.length > 0 ? true : false}
            helperText={props.errorNombre}
            fullWidth
            id="nombre"
            name="nombre"
            label="Nombre Completo"
            variant="outlined"
            size="small"
            defaultValue={props.nombre}
            onChange={handleChangeNombre}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box p={2}>
          <TextField
            error={props.errorCedula.length > 0 ? true : false}
            helperText={
              props.errorCedula.length === 0
                ? "Ingrese sin puntos y con guión"
                : props.errorCedula
            }
            fullWidth
            id="cedula"
            name="cedula"
            label="Rut"
            variant="outlined"
            size="small"
            defaultValue={props.cedula}
            onKeyPress={KeyPressCedula}
            onChange={handleChangeCedula}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box p={2}>
          <TextField
            error={props.errorPais.length > 0 ? true : false}
            helperText={props.errorPais}
            fullWidth
            id="pais"
            name="pais"
            label="Ciudad"
            variant="outlined"
            size="small"
            defaultValue={props.pais}
            onChange={handleChangePais}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box p={2}>
          <TextField
            error={props.errorTelefono.length > 0 ? true : false}
            helperText={props.errorTelefono}
            fullWidth
            id="telefono"
            name="telefono"
            label="Teléfono"
            variant="outlined"
            size="small"
            defaultValue={props.telefono}
            onChange={handleChangeTelefono}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
