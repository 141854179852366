import React from "react";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
//MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GoogleFonts from "../components/GoogleFonts";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: 64,
  },
}));

export default function CarolinaMolina() {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div className={classes.root} style={{ overflowX: "hidden" }}>
      <Grid container justify="space-around">
        {!matches ? (
          <Grid item xs={6} md={6}>
            <img alt="c" src="/images/carolinamolinaGrande.jpg" width="200" />
          </Grid>
        ) : (
          ""
        )}
        {!matches ? (
          <Grid item xs={6} md={12}>
            <Box display="flex" alignItems="center">
              <GoogleFonts
                textAlign="center"
                fontFamily="Courgette"
                fontSize={"2.8rem"}
                lineHeight="120%"
                m={2}
              >
                Psicóloga Carolina Molina Álvarez
              </GoogleFonts>
            </Box>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12} md={6}>
          <Box display="flex" alignItems="center">
            <Typography
              align="justify"
              component="div"
              style={{ width: "100%" }}
            >
              {matches ? (
                <Box
                  textAlign="center"
                  fontFamily="Courgette"
                  fontSize={"2.8rem"}
                  lineHeight="120%"
                  m={2}
                  pt={2}
                >
                  Psicóloga Carolina Molina Álvarez
                </Box>
              ) : (
                ""
              )}
              <Box
                textAlign="justify"
                fontFamily="Courgette"
                fontSize={"1.3rem"}
                lineHeight="120%"
                m={2}
                pt={2}
                style={{ color: "#7D3C98" }}
              >
                Carolina Molina Alvarez psicóloga clínica, diplomada en Terapia
                Infantojuvenil, psicología comunitaria, intervención social y
                políticas públicas, especializada en psicología bariátrica.
              </Box>
              <Box
                textAlign="justify"
                fontFamily="Yanone Kaffeesatz"
                fontSize={"1.4rem"}
                lineHeight="120%"
                m={2}
                pt={2}
              >
                Cuento con las herramientas necesarias para guiarte y que sepas
                sacar provecho de cada día enfrentando cualquier circunstancia
                que se te presente. Serás parte activa de tu proceso de
                recuperación, autoconocimiento y desarrollo personal. Me
                interesa generar un espacio de calidad y calidez que permita el
                surgimiento y la construcción de nuevas herramientas que puedan
                potenciar los recursos personales, familiares y sociales de cada
                paciente por medio de la gestión de sus emociones, desarrollo
                personal, autoestima, motivación y otros.
              </Box>
              <Box
                textAlign="justify"
                fontFamily="Yanone Kaffeesatz"
                fontSize={"1.4rem"}
                lineHeight="120%"
                m={2}
                pt={2}
              >
                Psicóloga Bariátrica, soy operada bariátrica por lo que conozco
                el proceso de cerca, trabajo con pacientes en proceso pre y
                poscirugía bariátrica, ayudándolos a La adquisición de un estilo
                de vida saludable y herramienta terapéuticas fundamentales para
                la mantención del peso a largo plazo, para lo cual un proceso de
                apoyo postoperatorio psicológico es fundamental. Centrándonos en
                el autocuidado, adherencia al tratamiento, imagen corporal,
                regulación de estrés, enfatizar detección de sintomatología de
                trastorno de la conducta alimentaria (TCA).
              </Box>
              <Box
                textAlign="justify"
                fontFamily="Courgette"
                fontSize={"1.3rem"}
                lineHeight="120%"
                style={{ color: "#7D3C98" }}
                m={2}
                pt={2}
              >
                Te invito a que te animes y me contactes, si ya tomaste la hora
                yo te enviare un WhatsApp para que coordinemos y hablemos 5 o 10
                min la idea es tener un primer contacto y saber un poco de ti.
                Recuerda que la sesión es online.
              </Box>
              <Box
                textAlign="justify"
                fontFamily="Yanone Kaffeesatz"
                fontSize={"1.3rem"}
                lineHeight="120%"
                m={2}
                pt={2}
              >
                Número de prestador superintendencia de salud: 599249
              </Box>
            </Typography>
          </Box>
        </Grid>
        {matches ? (
          <Grid item xs={12} md={6}>
            <img alt="c" src="/images/carolinamolinaGrande.jpg" />
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
}
