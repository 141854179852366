import React from "react";
import { makeStyles } from "@mui/styles";
import { useUsuario } from "../context/usuario";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function ButtonAppBar(props) {
  const { usuario } = useUsuario();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <img
              alt="mente sana"
              src="/images/logo2.png"
              style={{ width: "100%", maxWidth: "200px", height: "auto" }}
            ></img>
          </Typography>
          <Box m={1}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => props.setAbrirHora(true)}
            >
              Tomar Hora
            </Button>
          </Box>
          <Box m={1}>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() => props.setAbrirAnular(true)}
            >
              Anular Hora
            </Button>
          </Box>
          <IconButton
            aria-label="terapeuta"
            classes={{ label: classes.iconButtonLabel }}
            color="inherit"
            onClick={() => props.setAbrirTerapeuta(true)}
          >
            {usuario ? <AccountCircleIcon /> : <VpnKeyIcon />}
            <Typography variant="caption" className={classes.title}>
              {usuario ? "Entrar" : "Acceder"}
            </Typography>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}
