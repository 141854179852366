import React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EmailIcon from "@mui/icons-material/Email";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EventIcon from "@mui/icons-material/Event";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

export default function Paso6(props) {
  const getFecha = () => {
    let dt = new Date(props.fecha);
    let retorno =
      dt.getDate().toString() +
      "/" +
      (dt.getMonth() + 1).toString() +
      "/" +
      dt.getFullYear().toString();
    return retorno;
  };

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <List>
          <ListItem>
            <ListItemIcon>
              <LocalHospitalIcon />
            </ListItemIcon>
            <ListItemText primary="Previsión" secondary={props.prevision} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Email" secondary={props.correo} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="Terapeuta" secondary={props.terapeuta} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Día" secondary={getFecha()} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <QueryBuilderIcon />
            </ListItemIcon>
            <ListItemText primary="Hora" secondary={props.horaLabel} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}
