import React from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Divider from "@mui/material/Divider";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useUsuario } from "../context/usuario";
//componentes
import HorasTerapeuta from "../components/Terapeuta/HorasTerapeuta";
import Pacientes from "../components/Terapeuta/Pacientes";
import Datos from "../components/Terapeuta/Datos";
import HabilitarHoras from "../components/Terapeuta/HabilitarHoras";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: 80,
  },
}));

export default function Terapeuta() {
  const classes = useStyles();
  const { logout } = useUsuario();
  const [value, setValue] = React.useState(0);

  function vista() {
    let componente = "";
    switch (value) {
      case 0:
        componente = <HorasTerapeuta />;
        break;
      case 1:
        componente = <Pacientes />;
        break;
      case 2:
        componente = <Datos />;
        break;
      case 3:
        componente = <HabilitarHoras />;
        break;
      case 4:
        logout();
        break;
      default:
        componente = "";
        break;
    }

    return componente;
  }

  return (
    <Container className={classes.root} fixed>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
      >
        <BottomNavigationAction label="Horas" icon={<QueryBuilderIcon />} />
        <BottomNavigationAction
          label="Pacientes"
          icon={<AssignmentIndIcon />}
        />
        <BottomNavigationAction label="Mis Datos" icon={<PersonIcon />} />
        <BottomNavigationAction
          label="Disponibilizar"
          icon={<EventAvailableIcon />}
        />
        <BottomNavigationAction label="Salir" icon={<ExitToAppIcon />} />
      </BottomNavigation>
      <Divider />
      {vista()}
    </Container>
  );
}
