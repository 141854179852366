export const myConfig = {
  /*urlApi:{
        getTerapeutas: 'http://192.168.0.8:3500/msapi/getTerapeutas',
        getHoras: 'http://192.168.0.8:3500/msapi/getHoras/',
        setTomaHora: 'http://192.168.0.8:3500/msapi/setTomaHora',
        getHorasPaciente: 'http://192.168.0.8:3500/msapi/getHorasPaciente',
        setAnulaHora: 'http://192.168.0.8:3500/msapi/setAnulaHora'
        http://www.psicologiamentesana.cl
        
    },*/

  urlApi: {
    autenticar: "https://www.psicologiamentesana.cl/api/autenticar",
    whoami: "https://www.psicologiamentesana.cl/api/whoami",
    getTerapeutas: "https://www.psicologiamentesana.cl/api/getTerapeutas",
    getHoras: "https://www.psicologiamentesana.cl/api/getHoras",
    setTomaHora: "https://www.psicologiamentesana.cl/api/setTomaHora",
    getHorasPaciente: "https://www.psicologiamentesana.cl/api/getHorasPaciente",
    setAnulaHora: "https://www.psicologiamentesana.cl/api/setAnulaHora",
    login: "https://www.psicologiamentesana.cl/api/login",
    getHorasTerapeuta:
      "https://www.psicologiamentesana.cl/api/getHorasTerapeuta",
    getPacientes: "https://www.psicologiamentesana.cl/api/getPacientes",
    getTerapeuta: "https://www.psicologiamentesana.cl/api/getTerapeuta",
    setTerapeuta: "https://www.psicologiamentesana.cl/api/setTerapeuta",
    getHorasDisponibleTerapeuta:
      "https://www.psicologiamentesana.cl/api/getHorasDisponibleTerapeuta",
    setHorasDisponibleTerapeuta:
      "https://www.psicologiamentesana.cl/api/setHorasDisponibleTerapeuta",
    getHorasOcupadasPaciente:
      "https://www.psicologiamentesana.cl/api/getHorasOcupadasPaciente",
  },
};
