import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import ListaHoras from "./ListaHoras";
import Box from "@mui/material/Box";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import axios from "axios";
import { useUsuario } from "../../context/usuario";
import { myConfig } from "../../config";
import esLocale from "date-fns/locale/es";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledExpansionPanels() {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [horas, setHoras] = React.useState([]);
  const { usuario, logout } = useUsuario();

  useEffect(() => {
    async function Cargar() {
      try {
        let fecha =
          selectedDate.getFullYear().toString() +
          "-" +
          (selectedDate.getMonth() + 1).toString() +
          "-" +
          selectedDate.getDate().toString();
        let res = await axios.get(
          `${myConfig.urlApi.getHorasDisponibleTerapeuta}/${fecha}`,
          { headers: { Authorization: `Bearer ${usuario.token}` } }
        );
        let json = res.data;

        setHoras(json);
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          logout();
        }
      }
    }

    Cargar();
  }, [selectedDate]);

  return (
    <div className={classes.root}>
      <Box pt={3} pb={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <DatePicker
            label="Seleccione día"
            value={selectedDate}
            minDate={new Date()}
            onChange={handleDateChange}
            format="dd/MM/yyyy"
            animateYearScrolling
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
      <ListaHoras dataHoras={horas} selectedDate={selectedDate} />
    </div>
  );
}
