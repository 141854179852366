import React from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GoogleFonts from "../GoogleFonts";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function FAQS({ valores }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <GoogleFonts
            textAlign="left"
            fontFamily="Courgette"
            fontSize={"1.6rem"}
            lineHeight="120%"
            color="#19A200"
          >
            ¿Qué orientación teórica se utiliza?
          </GoogleFonts>
        </AccordionSummary>
        <AccordionDetails>
          <GoogleFonts fontFamily="Yanone Kaffeesatz" fontSize="1.2rem">
            Estoy convencida que la constante formación en nuevas terapias y
            técnicas, serán siempre beneficiosas para mis pacientes ya sea
            actuales o futuros, cada uno es distinto, por lo que una metodología
            no es suficiente, en ocasiones hay que aplicar de forma holística
            varias técnicas de distintas teorías, es por esto que hay que tomar
            las situaciones tal como son, trabajar con lo que se tiene y no
            intentar hacer un escenario ideal de aquello que no existe,
            trabajamos en conjunto con lo que hay, sin juicios.
          </GoogleFonts>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <GoogleFonts
            textAlign="left"
            fontFamily="Courgette"
            fontSize={"1.6rem"}
            lineHeight="120%"
            color="#7D3C98"
          >
            ¿Cuanto tiempo dura cada sesión?
          </GoogleFonts>
        </AccordionSummary>
        <AccordionDetails>
          <GoogleFonts fontFamily="Yanone Kaffeesatz" fontSize="1.2rem">
            Las sesiones duran 50 minutos a 1 hora.
          </GoogleFonts>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <GoogleFonts
            textAlign="left"
            fontFamily="Courgette"
            fontSize={"1.6rem"}
            lineHeight="120%"
            color="#19A200"
          >
            ¿Cómo obtengo la información de pago?
          </GoogleFonts>
        </AccordionSummary>
        <AccordionDetails>
          <GoogleFonts fontFamily="Yanone Kaffeesatz" fontSize="1.2rem">
            Al momento de tu agendar tu hora te llegara un correo de
            confirmación con toda la información necesaria para pago y compra de
            bonos etc.
          </GoogleFonts>
        </AccordionDetails>
      </Accordion>{" "}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <GoogleFonts
            textAlign="left"
            fontFamily="Courgette"
            fontSize={"1.6rem"}
            lineHeight="120%"
            color="#7D3C98"
          >
            ¿Cómo obtengo el enlace para las sesiones online?
          </GoogleFonts>
        </AccordionSummary>
        <AccordionDetails>
          <GoogleFonts fontFamily="Yanone Kaffeesatz" fontSize="1.2rem">
            Puedes contactar al WhatsApp de la consulta +56 9 6213 6261 o
            escribiendo al correo carolinamolina@psicologiamentesana.cl cuando
            agendes tu hora, de todas formas, yo me conectare antes de tu sesión
            contigo por medio del teléfono que dejaste en el momento de tomar tu
            hora, para coordinar los detalles de la sesión.
          </GoogleFonts>
        </AccordionDetails>
      </Accordion>{" "}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <GoogleFonts
            textAlign="left"
            fontFamily="Courgette"
            fontSize={"1.6rem"}
            lineHeight="120%"
            color="#19A200"
          >
            ¿Que convenio de salud tienen?
          </GoogleFonts>
        </AccordionSummary>
        <AccordionDetails>
          <GoogleFonts fontFamily="Yanone Kaffeesatz" fontSize="1.2rem">
            {`Tenemos convenios de salud con Fonasa con un valor de ${valores.fonasa} Para
            Isapres, éstas reembolzarán el valor de la consulta según plan del
            beneficiario.`}
          </GoogleFonts>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
