import React from "react";

import Container from "@mui/material/Container";

import PanelExpansion from "./PanelExpansion";

export default function HabilitarHoras(props) {
  return (
    <Container fixed>
      <PanelExpansion />
    </Container>
  );
}
